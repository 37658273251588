import React from "react"
import { Link } from "gatsby"

import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Row from "react-bootstrap/Row"
import { Nav, NavDropdown } from "react-bootstrap"

import "../custom.scss"

const Layout = ({ location, title, children }) => {

  return (
    <div>
      <Navbar bg="light" variant="light">
        <Container>
          <Link className="navbar-brand" to="/">
            Brian's Brain
          </Link>
          <Nav className="mr-auto">
            <Nav.Item><Link className="nav-link" to="/blog">Blog</Link></Nav.Item>
            <NavDropdown title="Projects" id="nav-dropdown">
              <Link className="dropdown-item" to="/projects/grail-diary">Grail Diary</Link>
              <Link className="dropdown-item" to="/projects/swanki">Swanki</Link>
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
      <Container>
        <div className="global-wrapper">
          <main>{children}</main>
          <footer>© {new Date().getFullYear()}</footer>
        </div>
      </Container>
    </div>
  )
}

export default Layout
